import React from 'react';

import phone from './phone.png'
import person from './person.png'
import viber from './viber.svg'
import email from './email.png'
import location from './location.png'

import './Contacts.scss'

const Contacts = () => {
  return (
    <div className="constacts_wrapper">
      <div className="contacts_page">
        <h1 className="title">Контакти</h1>
        <div className="contacts_items">
          <div className="item">
            <h1 className="item_title">КК "Козацький"</h1>
            <div className="subitem">
              <img src={email} alt="email"/>
              <span>kozatskyy.lviv.ua@gmail.com</span>
            </div>
            <div className="subitem">
              <img src={location} alt="address"/>
              <span>81131, Україна<br/>Пустомитівський р-н, Львівська обл.<br/>село Солонка<br/>вулиця Йосипа Сліпого, будинок, 2</span>
            </div>
          </div>

          <div className="item">
            <h1 className="item_title">Оператор </h1>
            <div className="subitem">
              <img src={phone} alt=""/>
              <span>067 486 7343</span>
            </div>
            <div className="subitem">
              <img src={phone} alt=""/>
              <span>063 679 3447</span>
            </div>
            <div className="subitem">
              <img src={person} alt=""/>
              <span>Олена</span>
            </div>
          </div>

          <div className="item">
            <h1 className="item_title">Керівник </h1>
            <div className="subitem">
              <img src={phone} alt=""/>
              <span>098 104 5006 </span>
            </div>
            <div className="subitem">
              <img src={person} alt=""/>
              <span>Дмитро</span>
            </div>
          </div>

          <div className="item">
            <h1 className="item_title">Соцмережі</h1>
            <div className="subitem">
              <img style={{width: "24px"}} src={viber} alt="viber"/>
              <a
                href="https://invite.viber.com/?g2=AQASywokW%2Bz4W0mvGsvRdUN%2BsKqLmbFO8Nt3LL9KzXlaFOrdXmaHRDnSzL3hYAl3">КК
                "Козацький" К-9</a>

            </div>
            <div className="subitem">
              <img style={{width: "24px"}} src={viber} alt="viber"/>
              <a href="https://invite.viber.com/?g2=AQBpTqUt0BFUSUiv3cMcZClt4uB%2BYldk30QJH13iczv69LIgnQIz1vAjblFnm7Nl">КК
                "Козацький" КЗ-8</a>
            </div>
          </div>

          <div className="item">
            <h1 className="item_title">Гаряча лінія/кол-центр ПрАТ «Львівобленерго»</h1>
            <div className="subitem">
              <img src={phone} alt="phone"/>
              <span>0 800 30 15 68 (безкоштовно)</span>
            </div>
            <div className="subitem">
              <img src={phone} alt="phone"/>
              <span>067 333 15 68 </span>
            </div>
            <div className="subitem">
              <img src={phone} alt="phone"/>
              <span>093 170 15 68 </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contacts;
