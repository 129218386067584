import React from 'react';
import { useHistory } from 'react-router-dom';
import { inject, observer } from "mobx-react";

import items from '../../const/navigationItems';
import visa from './visa-mastercard-logo.png';

import "./Footer.scss";

const Footer = ({ authModel: { user }, adminModel: { inbox_counter } }) => {
  const history = useHistory();

  const isCurrentTab = (item) => {
    return window.location.pathname.includes(item.path);
  };

  return (
    <div className="footer">
      <div className="footer_wrapper">
        <div className="links">
          <h1 className="title">Посилання:</h1>
          {items[user.role].map(((item, key) => (
            <h1 key={key} className={`item ${isCurrentTab(item) ? "active" : ""}`}
                onClick={() => {
                  history.push(item.path);
                  window.scrollTo(0, 0);
                }}>
              {item.name}
              {item.name === 'Питання' ?
                <span className='inbox_questions'>{inbox_counter < 100 ? inbox_counter : "99+"}</span> : null}

            </h1>
          )))}
        </div>
        <div className="contacts">
          <h1 className="title">Контакти КК "Козацький":</h1>
          <h1 className="item"><span className="item_position">Оператор:</span>067 486 7343 Олена</h1>
          <h1 className="item"><span className="item_position hidden">Оператор:</span>063 679 3447 Олена</h1>
          <h1 className="item"><span className="item_position">Керівник:</span>098 104 5006 Дмитро</h1>
          <h1 className="item"><span className="item_position">Email:</span>kozatskyy.lviv.ua@gmail.com</h1>
        </div>
        <div className="contacts">
          <h1 className="title">Гаряча лінія обленерго:</h1>
          <h1 className="item"><span className="footer_number">0 800 30 15 68 (безкоштовно)</span></h1>
          <h1 className="item"><span className="footer_number">067 333 15 68</span></h1>
          <h1 className="item"><span className="footer_number">093 170 15 68</span></h1>
        </div>
      </div>
      <img src={visa} width={100}/>
      <div className="footer_copyright">
        <p className="footer_copyright_text">
          © КК «Козацький». All right reserved.
          <span className="footer_copyright_company">
            Developed by
            <a href="http://sunscript.com.ua/" rel="noopener noreferrer" target="_blank"
               className="footer_copyright_link">
              SunScript.
            </a>
          </span>
        </p>
      </div>
    </div>
  );
};

export default inject('authModel', 'adminModel')(observer(Footer));
